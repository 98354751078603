<div id="dialog-tarifas-venture" class="dialog-container dialog-scroll container-fluid">
  <div class="header-dialog">
    <div class="text-end mb-2">
      <span class="material-symbols-outlined" [mat-dialog-close]="true" role="button">close</span>
    </div>
    <h3 class="me-4">¿Eres emprendedor?, ¡paga solo cuando alcances tu objetivo!</h3>
  </div>

  <div class="linea-separadora-1 border-primary-100 my-3"></div>

  <p class="p1-regular mb-3">Desde el Grupo SEGOFINANCE confiamos plenamente en el potencial de las compañías que
    ofrecemos a
    través de Sego Venture, por ello, <span class="p1-semibold">nuestras tarifas únicamente se aplicarán cuando la
  campaña se haya cerrado con éxito*</span>.</p>

  <div class="tarifas-tabla bg-secondary-venture-200 text-center p-4 mb-3">
    <div class="row d-flex align-items-center">
      <div class="col-md-5">
        <p class="p1-cifras primary-900 mb-0">1.800 €</p>
        <p class="p3-semibold primary-800">IVA no incluido</p>
        <p class="p1-medium neutral-900">al cierre de la campaña<br> de Crowdfunding</p>
      </div>
      <div class="col-md-2">
        <p class="p1-cifras primary-900">+</p>
      </div>
      <div class="col-md-5">
        <p class="p1-cifras primary-900 mb-0">6%</p>
        <p class="p3-semibold primary-800">IVA no incluido</p>
        <p class="p1-medium neutral-900">del total de fondos<br> conseguidos</p>
      </div>
    </div>
  </div>

  <p class="p1-semibold mb-2"> Como emprendedor podrás beneficiarte de los siguientes servicios: </p>

  <ul class="list-unstyled p2-regular ms-2">
    <li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
  Análisis del proyecto</li>
  <li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
Preparación campaña de marketing</li>
<li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
Ayuda con la estrategia de captación de capital</li>
<li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
Apoyo con la documentación legal</li>
<li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
Presentación del proyecto a nuestra red de inversores</li>
<li class="neutral-800"><span class="material-symbols-outlined alignCheck primary-900 mx-1">done</span>
Aparición en medios de comunicación y redes sociales </li>
</ul>

<div class="d-none d-md-block my-4 text-center">
  @if (data.plataformaId === 'VENTURE') {
    <a class="btn btn-primary mx-auto" title="Financia tu proyecto"
    routerLink="/buscas-financiacion" [mat-dialog-close]="true">Financia tu proyecto</a>
  }
  @if (data.plataformaId !== 'VENTURE') {
    <a class="btn btn-primary mx-auto" title="Financia tu proyecto"
    href="{{data.domainUrl}}/buscas-financiacion" [mat-dialog-close]="true">Financia tu proyecto</a>
  }
</div>

<p class="mt-4 mt-md-0 mb-mobile mb-lg-2 neutral-800 p3-regular">(*) TODOS nuestros servicios son a éxito, se cobran
  <span class="p3-semibold">si se alcanza el objetivo</span>.
</p>

<div class="d-block d-md-none fixed-bottom bg-white">

  <div class="linea-separadora-1 border-primary-100 my-4"></div>

  <div class="row">
    <div class="col-10 mx-auto text-center mb-4">
      @if (data.plataformaId === 'VENTURE') {
        <a class="btn btn-primary w-100" title="Financia tu proyecto"
        routerLink="/buscas-financiacion" [mat-dialog-close]="true">Financia tu proyecto</a>
      }
      @if (data.plataformaId !== 'VENTURE') {
        <a class="btn btn-primary w-100" title="Financia tu proyecto"
        href="{{data.domainUrl}}/buscas-financiacion" [mat-dialog-close]="true">Financia tu proyecto</a>
      }
    </div>
  </div>
</div>
</div>