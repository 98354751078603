import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { TarifasData } from 'src/app/footer/_models/tarifas-data';

@Component({
  selector: 'app-tarifas-venture',
  templateUrl: './tarifas-venture.component.html',
  styleUrls: ['./tarifas-venture.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule
  ]
})
export class TarifasVentureComponent
{

  constructor(
    public dialogRef: MatDialogRef<TarifasVentureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TarifasData
  ) { }

}
